<template>
  <div class="dialog">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="企业组织：">
        <el-radio-group v-model="radio" @change="handleChange">
          <el-radio :label="1">园区</el-radio>
          <el-radio :label="2">地块</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="企业组织：" prop="deptId">
        <el-select
          v-if="radio === 1"
          v-model="ruleForm.deptId"
          placeholder="请选择企业组织"
          clearable
          @change="handleChangeCascader"
        >
          <el-option
            v-for="item in sidebarData"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- <el-cascader
          v-else
          @change="handleChangeCascader"
          :options="sidebarData"
          :props="{ checkStrictly: true }"
          clearable
        ></el-cascader>-->
        <div v-else>
          <el-select v-model="ruleForm.deptId" placeholder="请选择企业组织" clearable @change="handleChangeDept">
            <el-option v-for="item in sidebarData" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
          <el-select v-model="lots" placeholder="请选择企业组织" clearable multiple collapse-tags @change="handleChangeLots">
            <el-option v-for="item in childrenArr" :key="item.id" :label="item.label" :value="item.id"></el-option>
          </el-select>
        </div>
      </el-form-item>
      <div class="btn-box">
        <el-button class="btn" type="primary" @click="submitForm('ruleForm')"
          >确定</el-button
        >
        <el-button @click="handleClose">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "EditUser",
  props: {
    handleClose: Function,
    sidebarData: Array,
    getUserUpdate: Function,
    paramsObj: Object,
  },
  data() {
    return {
      ruleForm: JSON.parse(JSON.stringify(this.paramsObj)),
      rules: {
        deptId: [
          { required: true, message: "请选择园区地块", trigger: "change" },
        ],
      },
      deptId: "",
      options1: [],
      options3: [],
      radio: 1,
      childrenArr: [],
      lots: [],
    };
  },
  mounted() {
    console.log("sidebarData", this.sidebarData);
    this.getUserDepInfo()
  },
  methods: {
    async getUserDepInfo(){
      const { data } = await this.$api.getUserDepInfo({ id: this.paramsObj.id });
      let {depId, lots, type} = data;
      console.log("data", data);
      this.ruleForm.depId = depId;
      this.handleChangeDept(depId);
      this.lots = lots;
      this.radio = type;
    },
    handleChangeLots(parmas) {
      console.log("parmas22222", parmas);
    },
    handleChangeDept(parmas) {
      console.log("parmas", parmas);
      this.lots = [];
      let data = this.sidebarData.filter(item => item.id == parmas);
      console.log(data); // [{name: "Apple", id: "1"}]
      this.childrenArr = data[0].children;
    },
    handleChange() {
      this.ruleForm.deptId = "";
    },
    handleChangeCascader(parmas) {
      if (typeof parmas == "object") {
        this.ruleForm.deptId = parmas[parmas.length - 1];
        return;
      }
      this.ruleForm.deptId = parmas;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getUserUpdate({id: this.ruleForm.id, depId: this.ruleForm.deptId, lots: this.lots, type: this.radio});
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-box {
  text-align: right;
  .btn {
    margin-right: 20px;
  }
}
</style>
